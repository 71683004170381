import React, { useState } from "react"
import { graphql} from "gatsby"
import Layout from "../components/layout"
import Card from 'react-bootstrap/Card'
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import '../styles/guide.css';

export const query = graphql`
  query($slug: String!) {
    contestDataset(SlugURL: { eq: $slug }) {
      Description
      FAQ
      FeaturedImageURL
      Id
      Rules
      SlugURL
      Title
      id
    }
  }
`


const Contest = ({ data }) => {
    // const contest = data.contestDataset

    const [ success, setSuccess ] = useState(false);
    const [ failure, setFailure] = useState(false);

    const [contestModalShow, setContestModalShow] = useState(false);

    const [ formDatas, setFormDatas ] = useState(
      {
        contestName: null,
        contestEmail: null,
        contestUrl: null,
        contestNumber: 0,
      }
    )

    const [ validation, setValidation ] = useState(
      {
        contestNameErr: null,
        contestEmailErr: null,
        contestUrlErr: null,
        contestNumberErr: null
      }
    )

    const handleChangeContestName = (event) => {
      
      setValidation((prevState) => {
        return { ...prevState, contestNameErr: null };
      });

      let currentValue = event.target.value;
      setFormDatas((prevState) => {
        return { ...prevState, contestName: currentValue };
      });
    }

    const handleChangeContestEmail = (event) => {
      setValidation((prevState) => {
        return { ...prevState, contestEmailErr: null };
      });

      let currentValue = event.target.value;
      setFormDatas( (prevState)=> {
        return { ...prevState, contestEmail: currentValue };
      });
    }

    const handleChangeContestNumber = (event) => {
      setValidation((prevState) => {
        return { ...prevState, contestNumberErr: null };
      });

      let currentValue = event.target.value;
      setFormDatas( (prevState)=> {
        return { ...prevState, contestNumber: currentValue };
      });
    }

    const handleChangeContestUrl = (event) => {
      setValidation((prevState) => {
        return { ...prevState, contestUrlErr: null };
      });

      let currentValue = event.target.value;
      setFormDatas( (prevState)=> {
        return { ...prevState, contestUrl: currentValue };
      });
    }

    const validateForm = () => {
      setSuccess(false);
      setFailure(false);

      if(!formDatas.contestName) {
        setValidation((prevState) => {
          return { ...prevState, contestNameErr: 'Please enter your name' };
        });
      } else {
        setValidation((prevState) => {
          return { ...prevState, contestNameErr: null };
        });
      }

      

      if(!formDatas.contestEmail || !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formDatas.contestEmail) ) {
        setValidation((prevState) => {
          return { ...prevState, contestEmailErr: 'Please enter a valid Email' };
        });
      } else {
        setValidation((prevState) => {
          return { ...prevState, contestEmailErr: null };
        });
      }
      
      if(!formDatas.contestNumber) {
        setValidation((prevState) => {
          return { ...prevState, contestNumberErr: 'Please enter your mobile number' };
        });
      }
      else if(formDatas.contestNumber.length !== 10) {
        setValidation((prevState) => {
          return { ...prevState, contestNumberErr: 'Please enter a valid number' };
        });
      } else {
        setValidation((prevState) => {
          return { ...prevState, contestNumberErr: null };
        });
      }

      if(!formDatas.contestUrl) {
        setValidation((prevState) => {
          return { ...prevState, contestUrlErr: 'Please enter your project url' };
        });
      } else {
        setValidation((prevState) => {
          return { ...prevState, contestUrlErr: null };
        });
      }

       if(validation.contestNameErr === null && validation.contestEmailErr === null && validation.contestUrlErr === null && validation.contestUrl !== "" && validation.contestNumberErr === null && formDatas.contestName !== "" && formDatas.contestEmail !== "" && formDatas.contestNumber.length === 10) {
        document.getElementById("contest-button").innerHTML = 'Please wait...';

        const opts = {
         "Name": formDatas.contestName,
         "EmailID": formDatas.contestEmail,
         "MobileNumber": formDatas.contestNumber,
         "URL": formDatas.contestUrl,
         "EmailTemplate": "CourseTemplate",
         "ReferenceId":data.contestDataset.Id,
         "ReferenceType":"Contest",
        }

        fetch('https://gsq4d48100.execute-api.ap-south-1.amazonaws.com/dev/leads/create-lead', {
            method: 'post',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(opts)
            }).then(function(response) {
              return response.json();
            }).then(function(data) {
              if(data.success) {
                setSuccess(true);
                
                document.getElementById("contest-button").innerHTML =  'Submit Project';
                setFormDatas(
                  {
                    contestName: '',
                    contestEmail: '',
                    contestUrl: '',
                    contestNumber: 0,
                  }
                );
                  
              } else {
                setFailure(true);
                document.getElementById("contest-button").innerHTML =  'Submit Project';
              }
              
            });
      }

      

    }

    const handlecontestModal = () => {
      setContestModalShow (!contestModalShow)
    }

    return (
        <Layout>
        <hr className="mt-0 mb-4 d-none d-md-block" />
        <div className="container">
        <div className="row">
        <Card className="border-0 mb-4">
          <div className="row">
            <div className="col-md-6 mb-4">
              <Card.Img src={data.contestDataset.FeaturedImageURL} variant="top"/>
            </div>
            <div className="col-md-6 pl-4 pr-4">
              <Card.Title style={{fontSize: "1.5rem"}}>{data.contestDataset.Title}</Card.Title>
              <div dangerouslySetInnerHTML={{__html:data.contestDataset.Description}}></div>
              <p>Rules:</p>
              <div dangerouslySetInnerHTML={{__html: data.contestDataset.Rules}} className="rulesParent"></div>
              <Button className="text-white btn btn-primary mt-4" onClick={handlecontestModal}>Submit Your Project</Button>
            </div>
          </div>
          
        </Card>
        </div>

        <div className="faqOuter">
          <h4>Faqs:</h4>
          <div
              dangerouslySetInnerHTML={{ __html: data.contestDataset.FAQ }}
          />
        </div>
        </div>

        <Modal className="guideModal" show={contestModalShow} onHide={handlecontestModal}>
            <Modal.Header><p>Almost there: please complete this form and click the button below to gain instant access. </p></Modal.Header>
            <Modal.Body>
              {success && <Alert variant="success" style={{fontSize: '14px', textAlign: 'center'}}>Thank you, your enquiry has been submitted ! </Alert>}
              {failure && <Alert variant="danger" style={{fontSize: '14px', textAlign: 'center'}}>Oops, something went wrong!</Alert>}
              <Form>
              <Form.Group controlId="formBasicName">
                <Form.Control type="text" name="contestName" onChange={handleChangeContestName} value={formDatas.contestName} placeholder="Name" />
                <Form.Text className="text-danger">{validation.contestNameErr}</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" name="contestEmail" onChange={handleChangeContestEmail} value={formDatas.contestEmail} placeholder="Email" />
                <Form.Text className="text-danger">{validation.contestEmailErr}</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicNumber">
                <Form.Control type="number" name="contestNumber" onChange={handleChangeContestNumber} value={ (formDatas.contestNumber === 0 ? '' : formDatas.contestNumber) } placeholder="Phone Number" />
                <Form.Text className="text-danger">{validation.contestNumberErr}</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicUrl">
                <Form.Control type="text" name="contestUrl" onChange={handleChangeContestUrl} value={ (formDatas.contestUrl) } placeholder="URL" />
                <Form.Text className="text-danger">{validation.contestUrlErr}</Form.Text>
              </Form.Group>
              <Form.Group className="text-center">
                <Button variant="primary" id="contest-button" onClick={validateForm}>Submit Project</Button>
              </Form.Group>

              </Form>
            </Modal.Body>
          </Modal>
        </Layout>
    )
}

export default Contest
